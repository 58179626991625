<template>
  <div>
    <v-container fluid px-0 py-2 class="Container_Nivel1_Notas">
      <v-data-iterator
        :items="listaCards"
        hide-default-footer
        v-show="!esperar"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [4],
        }">
        <template v-slot:default="props">
          <v-row justify="center">
            <v-col
              v-for="item in props.items"
              :key="item.ordenacao"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card :class="`elevation-${hover ? 5 : 1}`" class="clickable Card_Notas fill-height">
                  <v-card-title class="Card_Title pa-2" @click="selectItem(item)">
                    <p>{{ item.titulo }}</p>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text @click="selectItem(item)" class="Card_Content pa-0">
                    <v-row justify="center" align="center">
                      <v-col cols="12">
                        <p>
                          <span class="subheading">{{ $t('label.valor_total') }}</span> <br />
                          <span class="title">{{ getMoney(item.valorTotal) }}</span>
                        </p>
                        <p>
                          <span class="subheading">{{ $t('label.valor_nf_ipi') }}</span> <br />
                          <span class="title">{{ getMoney(item.valorLiquido) }}</span>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="Card_Actions">
                    <v-row align="center" justify="center">
                      <v-spacer />
                      <v-col cols="12" sm="2" style="text-align: end;" class="pa-0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-menu
                              v-if="exibirExportacao(item)"
                              ref="menu"
                              v-model="menu[item.ordenacao - 1]"
                              :key="item.ordenacao"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :disabled="bloquearBotaoExportacao"
                              transition="scale-transition"
                              offset-y
                              full-width
                              :min-width="isSellout(item) ? '290px' : '580px'"
                              :max-width="isSellout(item) ? '290px' : '580px'">
                              <template v-slot:activator="{ on: menu }">
                                <v-btn icon
                                  class="mx-3"
                                  v-on="{ ...menu, ...tooltip }">
                                  <v-icon>get_app</v-icon>
                                </v-btn>
                              </template>
                              <v-card fluid>
                                <v-container>
                                  <v-row>
                                    <v-col cols="6">
                                      <v-select
                                        item-text="text"
                                        item-value="value"
                                        v-if="!isSellout(item)"
                                        v-model="filtro.tipoData"
                                        :items="listaTiposData"
                                        :label="`${$tc('label.aplicar_se_a', 1)} *`">
                                      </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-select
                                        item-text="text"
                                        item-value="value"
                                        v-if="!isSellout(item)"
                                        v-model="filtro.tipoNota"
                                        :items="listaTiposNota"
                                        :label="`${$tc('label.agrupamento', 1)} *`">
                                      </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-autocomplete
                                        id="filtro-autocomplete-grupo-fornecedor"
                                        class="custom-autocomplete"
                                        v-if="!isSellout(item)"
                                        v-model="filtro.grupoFornecedor"
                                        :items="gruposFornecedores"
                                        :label="`${$tc('label.grupo_fornecedor', 1)}`"
                                        :placeholder="$tc('label.digite_para_buscar', 1)"
                                        :no-data-text="$tc('message.nenhum_registro', 1)"
                                        item-text="nomExtensao"
                                        item-value="id"
                                        :clearable="true"
                                        return-object
                                        @click:append="() => triggerSelecao('filtro-autocomplete-grupo-fornecedor')"
                                        @click.native="(i) => buscaAutocomplete(i, buscaGruposFornecedores)"
                                        :search-input.sync="triggerGrupoFornecedor">
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-autocomplete
                                        id="filtro-autocomplete-fornecedor"
                                        class="custom-autocomplete"
                                        v-if="!isSellout(item)"
                                        v-model="filtro.fornecedor"
                                        :filter="filtrarFornecedores"
                                        :items="fornecedores"
                                        :label="`${$tc('label.fornecedor', 1)}`"
                                        :placeholder="$tc('label.digite_para_buscar', 1)"
                                        :no-data-text="$tc('message.nenhum_registro', 1)"
                                        item-text="razaoSocial"
                                        item-value="id"
                                        :clearable="true"
                                        return-object
                                        @click:append="() => triggerSelecao('filtro-autocomplete-fornecedor')"
                                        @click.native="(i) => buscaAutocomplete(i, buscaFornecedores)"
                                        :search-input.sync="triggerFornecedor">
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                </v-container>
                                <v-date-picker
                                  locale="pt-br"
                                  v-model="months"
                                  full-width
                                  type="month"
                                  no-title
                                  multiple
                                  color="primary">
                                  <v-spacer></v-spacer>
                                  <v-btn text color="primary"
                                    @click.native="fecharModalCalendario(item.ordenacao - 1)">
                                      {{ $t('label.cancelar') }}
                                  </v-btn>
                                  <v-btn text color="primary"
                                    @click.native="abrirModalConfirmacao('exportar', item)">
                                    {{ $t('label.confirmar') }}
                                  </v-btn>
                                </v-date-picker>
                              </v-card>
                            </v-menu>
                          </template>
                          {{ $t('label.exportar') }}
                        </v-tooltip>
                        <v-tooltip bottom style="min-width: 290px;">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-if="!exibirExportacao(item)"
                              v-on="on"
                              color="warning"
                              class="mx-3">
                              error_outline
                            </v-icon>
                          </template>
                          <span>{{ item.qtd }} {{ $tc('label.inconsistencia', 2) }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao"
      @agree="confirmaExportacao"
      @disagree="cancelarExportacao">
    </confirm>
  </div>
</template>

<script>
import {
  getMoney,
} from '@/common/functions/helpers';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import Confirm from '@/shared-components/dialog/Confirm';
import { buscaAutocomplete, triggerSelecao } from '@/common/functions/autocomplete-utils';
import { executarExportacao, verificarDadosExportacao } from '@/common/resources/solicitacao-exportacao-assincrona';

export default {
  name: 'NotasNivel1DadosAgrupado',
  components: {
    Confirm,
  },
  props: {
  },
  data() {
    return {
      resources: this.$api.notas(this.$resource),
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      exportacaoAssincronaResource: this.$api.solicitacaoExportacaoAssincrona(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      exportacaoAssincrona: null,
      listaCards: [],
      rowsPerPageItems: [4],
      esperar: false,
      menu: [
        false,
        false,
        false,
      ],
      date: new Date().toISOString().substr(0, 10),
      mensagemConfirmacao: '',
      bloquearBotaoExportacao: false,
      notaSelecionada: {},
      months: [],
      triggerFornecedor: null,
      triggerGrupoFornecedor: null,
      fornecedores: [],
      gruposFornecedores: [],
      filtro: {
        tipoData: 'dataConferencia',
        tipoNota: 'SELLIN_POR_LINHA',
        fornecedor: null,
        grupoFornecedor: null,
      },
      listaTiposData: [
        { text: this.$tc('label.data_conferencia', 1), value: 'dataConferencia' },
        { text: this.$tc('label.data_emissao', 1), value: 'dataEmissao' },
      ],
      listaTiposNota: [
        { text: this.$tc('label.linha', 1), value: 'SELLIN_POR_LINHA' },
        { text: this.$tc('label.familia', 1), value: 'SELLIN_POR_FAMILIA' },
        { text: this.$tc('label.produto', 1), value: 'SELLIN_POR_PRODUTO' },
        { text: this.$tc('label.nota_fiscal_agrupada', 1), value: 'SELLIN_POR_NF' },
        { text: this.$tc('label.nota_fiscal_item', 1), value: 'SELLIN_POR_NF_ITEM' },
        { text: this.$tc('label.fornecedor', 1), value: 'SELLIN_POR_FORNECEDOR' },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    triggerGrupoFornecedor(val) {
      const { grupoFornecedor } = this.filtro;
      if (grupoFornecedor && grupoFornecedor.nomExtensao === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaGruposFornecedores(val);
      }, 500);
    },
    triggerFornecedor(val) {
      const { fornecedor } = this.filtro;
      if (fornecedor && fornecedor.razaoSocial === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaFornecedores(val);
      }, 500);
    },
  },
  computed: {
    ...generateComputed('NOTA', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
  },
  methods: {
    getMoney,
    buscaAutocomplete,
    triggerSelecao,

    isSellout(nota) {
      return nota.tipo === 'SELLOUT';
    },
    selectItem(item) {
      this.$emit('Notas__SelecionaItemNivel1', item);
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    buscar() {
      this.resources.listarNotasAgrupadasPorTipo().then((response) => {
        this.listaCards = response.data;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    filtrarFornecedores(item, queryText) {
      return item.codNomeCnpj.toLowerCase().indexOf(queryText.toLowerCase()) > -1
        || item.desCnpj.replace(/[./-]/g, '').indexOf(queryText) > -1;
    },
    buscaGruposFornecedores(autocomplete) {
      const parametros = {
        autocomplete,
      };
      this.planejamentoAcaoResource.buscarGruposFornecedores(parametros)
        .then((res) => {
          this.gruposFornecedores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaFornecedores(autocomplete) {
      const parametros = {
        autocomplete,
        pagador: false,
      };
      if (this.filtro.grupoFornecedor) {
        parametros.idGrupoFornecedor = this.filtro.grupoFornecedor.id;
      }
      this.planejamentoAcaoResource.buscarFornecedores(parametros)
        .then((res) => {
          this.fornecedores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    exibirExportacao(nota) {
      if (nota.tipo === 'INCONSISTENCIA') {
        return false;
      }
      return true;
    },
    abrirModalConfirmacao(acao, nota) {
      if (acao === 'exportar') {
        if (!this.months.length) {
          this.$toast(this.$t('errors.periodo.obrigatorio'));
          return;
        }
        this.bloquearBotaoExportacao = true;
        this.notaSelecionada = nota;
        this.mensagemConfirmacao = this.$t('message.deseja_exportar');
        setTimeout(() => this.$refs.modalConfirmacao.open());
      }
    },
    fecharModalCalendario(index) {
      this.menu[index] = false;
      this.$forceUpdate();
    },
    confirmaExportacao() {
      const tipoNota = this.isSellout(this.notaSelecionada)
        ? this.notaSelecionada.tipo : this.filtro.tipoNota;
      const meses = this.months.map((mes) => parseInt(mes.split('-').join(''), 10));
      const { tipoData } = this.filtro;
      const idFornecedor = this.filtro.fornecedor ? this.filtro.fornecedor.id : null;
      const idGrupoFornecedor = this.filtro.grupoFornecedor ? this.filtro.grupoFornecedor.id : null;

      if (!this.exportacaoAssincrona) {
        this.resources.verificarDadosExportacao({
          tipoNota,
          meses,
          tipoData,
          idFornecedor,
          idGrupoFornecedor,
        })
          .then((response) => {
            this.pararEsperar();
            if (response.body) {
              this.$emit('TABELA_EXPORTAR_DADOS',
                () => { this.bloquearBotaoExportacao = false; },
                this.notaSelecionada,
                tipoNota,
                meses,
                tipoData,
                idFornecedor,
                idGrupoFornecedor);
            } else {
              this.bloquearBotaoExportacao = false;
              this.$toast(this.$t('message.sem_dados_exportacao_periodo'));
            }
          }).catch((err) => {
            this.pararEsperar();
            this.$error(this, err);
          }).finally(() => {
            this.bloquearBotaoExportacao = false;
            this.months = [];
          });
      } else {
        const params = {
          anoMes: meses,
          tipoData,
          tipoNota,
          idFornecedor,
          idGrupoFornecedor,
        };
        verificarDadosExportacao(params, this.$resource).then((response) => {
          this.pararEsperar();
          if (response.body) {
            this.executarExportacaoAssincrona(params);
          } else {
            this.bloquearBotaoExportacao = false;
            this.$toast(this.$t('message.sem_dados_exportacao_periodo'));
          }
        }).catch((err) => {
          this.pararEsperar();
          this.$error(this, err);
        }).finally(() => {
          this.bloquearBotaoExportacao = false;
          this.months = [];
        });
      }
    },
    executarExportacaoAssincrona(params) {
      executarExportacao(params, this.$resource)
        .then(() => {
          this.pararEsperar();
          this.bloquearBotaoExportacao = false;
          this.$toast(this.$t('message.exportacao_em_andamento_notificacao_email'));
        }, (err) => {
          this.pararEsperar();
          this.bloquearBotaoExportacao = false;
          this.$error(this, err);
        });
    },
    cancelarExportacao() {
      this.bloquearBotaoExportacao = false;
      this.notaSelecionada = {};
    },
    verificaExportacaoAssincrona() {
      this.configSistemaResource.buscarChave({ chave: 'EXPORTACAO_ASSINCRONA' })
        .then((resp) => {
          this.exportacaoAssincrona = resp.body;
        });
    },
  },
  mounted() {
    this.filtrar();
    this.verificaExportacaoAssincrona();
  },
};
</script>

<style>
.Container_Nivel1_Notas .Container_Cards {
  padding: 1em 4px 7px 4px;
}
.Container_Nivel1_Notas .Card_Recebimento {
  min-height: 9.5em;
}
.Container_Nivel1_Notas .Card_Title, .Container_Nivel1_Notas .Card_Content {
  color: #757575;
  text-align: center;
  display: block;
  padding: 0;
}
.Container_Nivel1_Notas .Card_Title p {
  font-size: 1.1em;
  font-weight: 500;
  padding: 0;
  margin: 0px;
}
.Container_Nivel1_Notas .Card_Content p {
  font-size: 1.5em;
  font-weight: 300;
  padding: 0.3em;
  margin: 0px;
}
.Container_Nivel1_Notas .Card_Actions {
  min-height: 1em;
}
.Container_Nivel1_Notas .Card_Actions button, .Container_Nivel1_Notas .Card_Actions span {
  min-width: 12%;
  width: 25px;
  font-size: 18px;
  text-align: center;
}
.Container_Nivel1_Notas .Card_Actions button {
  color: dimgray;
}
.clickable {
  cursor: pointer;
}
.botaoInconsistencia {
  height: 2em !important;
  width: 2em !important;
  font-size: small !important;
  color: white !important;
}
</style>
